import useResultForm, {
  FORM_FIELD,
  getFormResult,
  isComplete,
} from "hook/useResultForm";
import { CREATE_ORDER } from "api/graphql/Order";
import { CREATE_SHIPMENT } from "../../../api/graphql/Shipment";
import { always } from "util/func";
import {
  cargoFormToGql,
  getCargoFormFields,
  getCargoFormFieldsEmpty,
} from "../cargo/model";
import { caseMap } from "@s-e/frontend/flow-control";
import { contactFormToGql, getDetailsFormFields } from "../contact/model";
import { createContextHook } from "util/react";
import { differenceInHours, isValid, parseISO } from "date-fns";
import { every, putIntoArray } from "util/array";
import { getFormAddressLine } from "components/order/UserInfoOverview";
import { hasLength } from "@s-e/frontend/pred";
import { isDateFmtValid, lengthEq, lengthLt, lt } from "util/pred";
import { mNumber } from "util/number";
import { useDebounce } from "hook/useDebounce";
import { useEffect, useMemo, useState } from "react";
import { useMutation } from "@apollo/client";
import { useSettings } from "feature/settings/context/SettingsContext";
import { useTranslation } from "react-i18next";
import {
  Async,
  First,
  Maybe,
  chain,
  constant,
  either,
  Last,
  curry,
  equals,
  filter,
  getPath,
  getProp,
  getPropOr,
  hasProp,
  identity,
  ifElse,
  isArray,
  isEmpty,
  isObject,
  isTruthy,
  map,
  mreduce,
  mreduceMap,
  not,
  objOf,
  option,
  pick,
  pipe,
  propSatisfies,
  reduce,
  safe,
  tap,
} from "crocks";

export const hasLengthAsString = (foldable) =>
  foldable ? String(foldable).length > 0 : false;

/**
 * Context for creating a brand new Order up until the commiting it to backend.
 */
export const {
  ClientOrderContext,
  ClientOrderContextProvider,
  useClientOrder,
} = createContextHook("ClientOrder", () => {
  const [wasSubmitted, setWasSubmitted] = useState(false);
  const { t } = useTranslation();
  const [storageUsed, setStorageUsed] = useState(false);
  const origin = useResultForm(
    getResultFormFields({ type: "from", t, wasSubmitted }),
  );
  const destination = useResultForm(
    getResultFormFields({ type: "to", t, wasSubmitted }),
  );
  const pickupDatetime = useResultForm(
    getTimeResultFormFields({ type: "from", t }),
  );
  const deliveryDatetime = useResultForm(
    getTimeResultFormFields({ type: "to", t }),
  );
  const cargo = useState([getCargoFormFieldsEmpty()]);
  const pickupDetails = useResultForm(
    getDetailsFormFields({ t, type: "from", wasSubmitted }),
  );
  const deliveryDetails = useResultForm(
    getDetailsFormFields({ t, type: "to", wasSubmitted }),
  );
  const courierShipping = useState(null);
  const general = useResultForm({
    description: FORM_FIELD.TEXT({
      opt: true,
      validator: always(true),
      props: {
        placeholder: always(t("order_description")),
        rows: always(7),
        "data-testid": always("description"),
      },
    }),
    pictures: FORM_FIELD.TEXT({
      initial: [],
      validator: always(true),
      opt: true,
      props: {
        value: ({ value }) => value,
        onChange: ({ set, value }) =>
          pipe(
            putIntoArray,
            reduce(
              (carry, item) => [
                ...carry,
                ...(!(item instanceof File) && carry.some(equals(item))
                  ? []
                  : [item]),
              ],
              [],
            ),
            set,
          ),
        "data-testid": always("pictures"),
      },
    }),
  });

  const [mutationCreateOrder, mutationCreateOrderResult] =
    useMutation(CREATE_ORDER);
  const [mutationCreateShipment] = useMutation(CREATE_SHIPMENT);

  const areLocationsValid = useMemo(() => {
    return (
      origin.isComplete &&
      destination.isComplete &&
      !areAddressesSame(origin.form, destination.form)
    );
  }, [origin.result.toString(), destination.result.toString()]);

  const areTimesValid = useMemo(() => {
    const basicValidation =
      pickupDatetime.isComplete && pickupDatetime.isComplete;

    const timeFrameValidation = pipe(
      filter(isTruthy),
      caseMap(
        () => false,
        [
          [lengthLt(2), always(true)],
          [lengthEq(2), pipe((dt) => differenceInHours(...dt), lt(0))],
        ],
      ),
    )([getFormDt(pickupDatetime), getFormDt(deliveryDatetime)]);

    return basicValidation && timeFrameValidation;
  }, [pickupDatetime.result.toString(), deliveryDatetime.result.toString()]);

  useEffect(() => {
    pipe(
      safe(not(isEmpty)),
      map(JSON.parse),
      map(
        tap((value) => {
          origin.setForm(value?.origin);
          destination.setForm(value?.destination);
          pickupDatetime.setForm(value?.pickup);
          deliveryDatetime.setForm(value?.delivery);
          cargo[1](value?.cargo || []);
          pickupDetails.setForm(value?.pickupDetails);
          deliveryDetails.setForm(value?.deliveryDetails);
          general.setForm(value?.general);
          courierShipping[1](value?.courierShipping);
        }),
      ),
      either(
        () => setTimeout(() => setStorageUsed(true), 1000),
        () => setTimeout(() => setStorageUsed(true), 1000),
      ),
    )(localStorage.getItem(SESSION_STORAGE_KEY));
  }, []);

  const save = useMemo(
    () => () =>
      localStorage.setItem(
        SESSION_STORAGE_KEY,
        JSON.stringify({
          cargo: cargo[0],
          courierShipping: courierShipping[0],
          delivery: deliveryDatetime.form,
          deliveryDetails: deliveryDetails.form,
          destination: destination.form,
          origin: origin.form,
          pickup: pickupDatetime.form,
          pickupDetails: pickupDetails.form,
          general: {
            ...general.form,
            pictures: general.form.pictures?.filter(
              (value) => !(value instanceof File),
            ),
          },
        }),
      ),
    [
      ...[
        deliveryDatetime,
        deliveryDetails,
        destination,
        general,
        origin,
        pickupDatetime,
        pickupDetails,
      ].map((resultForm) => resultForm.result.toString()),
      JSON.stringify(cargo[0]),
      JSON.stringify(courierShipping[0]),
    ],
  );

  useDebounce(
    () => {
      if (!storageUsed) return;
      save();
    },
    1000,
    [save, storageUsed],
  );

  const setCargo = useMemo(
    () =>
      curry((index, form) =>
        cargo[1]((s) => [
          ...s.slice(0, index),
          ...(not(isEmpty, form) ? [form] : []),
          ...s.slice(index + 1),
        ]),
      ),
    [JSON.stringify(cargo[1])],
  );

  const validCargo = useMemo(
    () =>
      cargo[0]?.map((item) => {
        return isComplete(
          getFormResult(getCargoFormFields({ t, form: item }), item),
        );
      }),
    [cargo[0]],
  );

  const { insuranceRate } = useSettings();

  const locationToGql = (locationForm, detailsForm) => ({
    address: locationForm?.street,
    building_no: locationForm?.house,
    city: locationForm?.city,
    country: locationForm?.country,
    door_no: locationForm?.flat,
    floor: detailsForm?.floor,
    full_address: getFormAddressLine(locationForm),
    is_elevator_available: detailsForm?.hasLift,
    is_extraction_assisted: detailsForm?.carryYourself,
    is_extraction_required: detailsForm?.needTakeout,
    zip_code: locationForm?.zipCode,
  });

  const toCreateGql = (clientId) => ({
    client_id: clientId,
    description: general.getValid("description") ?? null,
    from: {
      data: locationToGql(origin.form, pickupDetails.form),
      on_conflict: {
        constraint: "delivery_address_pkey",
        update_columns: [
          "address",
          "building_no",
          "city",
          "country",
          "created_at",
          "door_no",
          "floor",
          "full_address",
          "id",
          "is_elevator_available",
          "is_extraction_assisted",
          "is_extraction_required",
          "zip_code",
        ],
      },
    },
    to: {
      data: locationToGql(destination.form, deliveryDetails.form),
      on_conflict: {
        constraint: "delivery_address_pkey",
        update_columns: [
          "address",
          "building_no",
          "city",
          "country",
          "created_at",
          "door_no",
          "floor",
          "full_address",
          "id",
          "is_elevator_available",
          "is_extraction_assisted",
          "is_extraction_required",
          "zip_code",
        ],
      },
    },
    insurance_rate: insuranceRate,

    insurance_price: cargo[0].reduce(
      (carry, cargo) =>
        Maybe.of(cargo)
          .chain(safe(propSatisfies("isInsuranceNeeded", isTruthy)))
          .chain((cargo) =>
            Maybe.of((quantity) => (price) => quantity * price)
              .ap(getProp("quantity", cargo).chain(mNumber).alt(Maybe.of(1)))
              .ap(
                getProp("price", cargo)
                  .chain(mNumber)
                  .map((num) => num * insuranceRate * 0.01)
                  .alt(Maybe.of(0)),
              ),
          )
          .map((num) => carry + num)
          .option(carry),
      0,
    ),

    price: cargo[0].reduce(
      (carry, cargo) =>
        Maybe.of(cargo)
          .chain((cargo) =>
            Maybe.of((quantity) => (price) => quantity * price)
              .ap(getProp("quantity", cargo).chain(mNumber).alt(Maybe.of(1)))
              .ap(
                getProp("price", cargo)
                  .chain(mNumber)
                  .map(
                    cargo?.isInsuranceNeeded
                      ? (num) => num * insuranceRate * 0.01 + num
                      : identity,
                  )
                  .alt(Maybe.of(0)),
              ),
          )
          .map((num) => carry + num)
          .option(carry),
      0,
    ),

    wanted_pickup_date_from: getPickupDeliveryDatetime(
      pickupDatetime.form.date,
      pickupDatetime.form.time,
      First,
    ),

    wanted_pickup_date_to: getPickupDeliveryDatetime(
      pickupDatetime.form.date,
      pickupDatetime.form.time,
      Last,
    ),

    wanted_delivery_date_from: getPickupDeliveryDatetime(
      deliveryDatetime.form.date,
      deliveryDatetime.form.time,
      First,
    ),

    wanted_delivery_date_to: getPickupDeliveryDatetime(
      deliveryDatetime.form.date,
      deliveryDatetime.form.time,
      Last,
    ),

    pictures: {
      data: general.form.pictures.reduce(
        (carry, picture) =>
          getProp("optimizedUrl", picture)
            .chain(safe(not(isEmpty)))
            .map(objOf("src"))
            .map((pic) => [...carry, pic])
            .option(carry),
        [],
      ),
      on_conflict: {
        constraint: "pictures_pkey",
        update_columns: ["created_at", "id", "order_id", "src", "updated_at"],
      },
    },

    order_cargos: {
      data: cargo[0].map(cargoFormToGql(insuranceRate)),
      on_conflict: {
        constraint: "cargo_pkey",
        update_columns: [
          "category_id",
          "description",
          "height",
          "id",
          "insured_price",
          "is_insured",
          "is_packed",
          "length",
          "price",
          "quantity",
          "weight",
          "width",
        ],
      },
    },

    order_sender: {
      data: contactFormToGql(pickupDetails.form),
      on_conflict: {
        constraint: "order_contact_pkey",
        update_columns: [
          "comment",
          "company_name",
          "company_phone",
          "email",
          "id",
          "name",
          "phone",
        ],
      },
    },

    order_receiver: {
      data: contactFormToGql(deliveryDetails.form),
      on_conflict: {
        constraint: "order_contact_pkey",
        update_columns: [
          "comment",
          "company_name",
          "company_phone",
          "email",
          "id",
          "name",
          "phone",
        ],
      },
    },

    ...safe(isObject, courierShipping[0])
      .map(
        pick([
          "confirmed",
          "created_at",
          "id",
          "manifest_id",
          "method",
          "order_id",
          "packages",
          "price",
          "service_provider",
          "status",
          "tracking_no",
          "tracking_url",
          "updated_at",
        ]),
      )
      .chain(safe(not(isEmpty)))
      .map((data) => {
        return {
          payment_price: data?.price,
          order_shipment: {
            data,
            on_conflict: {
              constraint: "shipment_pkey",
              update_columns: [
                "confirmed",
                "id",
                "manifest_id",
                "method",
                "packages",
                "price",
                "service_provider",
                "status",
                "tracking_no",
                "tracking_url",
              ],
            },
          },
        };
      })
      .option({}),
  });

  const commitCreateOrder = (clientId) =>
    Async.fromPromise(mutationCreateOrder)({
      variables: { object: toCreateGql(clientId) },
    }).map(tap(() => localStorage.removeItem(SESSION_STORAGE_KEY)));

  return {
    commitCreateOrder,
    courierShipping,
    toCreateGql,
    areLocationsValid,
    areTimesValid,
    validCargo,
    isFullyValid: areLocationsValid && areTimesValid,
    origin,
    destination,
    pickupDatetime,
    deliveryDatetime,
    geocodeElementToResult: geocodeLenses.getFullForm,
    areAddressesSame: areAddressesSame(origin.form, destination.form),
    cargo: cargo[0],
    setCargo,
    setCargos: cargo[1],
    pickupDetails,
    deliveryDetails,
    general,
    addCargo: () => setCargo(cargo[0]?.length, getCargoFormFieldsEmpty()),
    rmCargo: (index) => setCargo(index, null),
    save,
    wasSubmitted,
    setWasSubmitted,
    isEverythingTransport: cargo[0].every(a => a.category?.rawName === 'category.transport'),
  };
});

const geocodeLenses = {
  getAddress: (result) =>
    [
      geocodeLenses.getAddressComponentShort(["route"], result),
      [
        geocodeLenses.getAddressComponentShort(["street_number"], result),
        geocodeLenses.getAddressComponentShort(
          ["subpremise", "subpremise"],
          result,
        ),
      ]
        .filter(isTruthy)
        .join("-"),
      geocodeLenses.getAddressComponentShort(
        [
          "locality",
          "administrative_area_level_2",
          "administrative_area_level_1",
        ],
        result,
      ),
      [
        geocodeLenses.getAddressComponentShort(["postal_code"], result),
        geocodeLenses.getAddressComponent(["country"], result),
      ]
        .filter(isTruthy)
        .join(" "),
    ]
      .filter(isTruthy)
      .join(", "),
  getAddressComponentShort: curry((types, element) =>
    pipe(
      getProp("address_components"),
      chain(safe(isArray)),
      chain(
        mreduceMap(
          First,
          safe(
            propSatisfies("types", (value) =>
              value.some((someType) => types.includes(someType)),
            ),
          ),
        ),
      ),
      chain(getProp("short_name")),
      option(""),
    )(element),
  ),
  getAddressComponent: curry((types, element) =>
    pipe(
      getProp("address_components"),
      chain(safe(isArray)),
      chain(
        mreduceMap(
          First,
          safe(
            propSatisfies("types", (value) =>
              value.some((someType) => types.includes(someType)),
            ),
          ),
        ),
      ),
      chain(getProp("long_name")),
      option(""),
    )(element),
  ),
  getGeometry: pipe(
    getPath(["geometry", "location"]),
    chain(safe(hasProp("toJSON"))),
    map((value) => value.toJSON()),
    option(null),
  ),
  getFullForm: (result) => ({
    address: geocodeLenses.getAddress(result),
    flat: geocodeLenses.getAddressComponent(
      ["subpremise", "subpremise"],
      result,
    ),
    house: geocodeLenses.getAddressComponent(["street_number"], result),
    street: geocodeLenses.getAddressComponent(["route"], result),
    city: geocodeLenses.getAddressComponent(
      [
        "locality",
        "administrative_area_level_2",
        "administrative_area_level_1",
      ],
      result,
    ),
    country: geocodeLenses.getAddressComponent(["country"], result),
    zipCode: geocodeLenses.getAddressComponent(["postal_code"], result),
    geometry: geocodeLenses.getGeometry(result),
  }),
};

const SESSION_STORAGE_KEY = "ClientOrderContext";

const getResultFormFields = ({ type, t, wasSubmitted }) => ({
  geometry: FORM_FIELD.TEXT({
    initial: "",
    validator: not(isEmpty),
    message: t("geometry missing"),
  }),
  address: FORM_FIELD.TEXT({
    initial: "",
    validator: always(true),
    message: t("address_not_found"),
    showValidationBelow: wasSubmitted,
    props: {
      placeholder: constant(""),
      "data-testid": () => `${type}-address`,
    },
  }),
  street: FORM_FIELD.TEXT({
    validator: hasLength,
    message: t("required_street"),
    showValidationBelow: wasSubmitted,
    props: {
      placeholder: () => t("street"),
      isInvalid: ({ isValid }) => !isValid,
      "data-testid": () => `${type}-street`,
    },
  }),
  house: FORM_FIELD.TEXT({
    validator: hasLengthAsString,
    message: t("required_building_no"),
    showValidationBelow: wasSubmitted,
    props: {
      placeholder: () => t("house_number"),
      isInvalid: ({ isValid }) => !isValid,
      "data-testid": () => `${type}-house`,
    },
  }),
  flat: FORM_FIELD.TEXT({
    validator: () => true,
    message: t("required_door_number"),
    showValidationBelow: wasSubmitted,
    props: {
      placeholder: () => t("flat_number"),
      isInvalid: ({ isValid }) => !isValid,
      "data-testid": () => `${type}-flat`,
    },
  }),
  city: FORM_FIELD.TEXT({
    validator: hasLength,
    message: t("required_city"),
    showValidationBelow: wasSubmitted,
    props: {
      placeholder: () => t("city"),
      isInvalid: ({ isValid }) => !isValid,
      "data-testid": () => `${type}-city`,
    },
  }),
  country: FORM_FIELD.TEXT({
    validator: hasLength,
    message: t("required_country"),
    showValidationBelow: wasSubmitted,
    props: {
      placeholder: () => t("country"),
      isInvalid: ({ isValid }) => !isValid,
      "data-testid": () => `${type}-country`,
    },
  }),
  zipCode: FORM_FIELD.TEXT({
    validator: hasLength,
    message: t("required_zip_code"),
    showValidationBelow: wasSubmitted,
    props: {
      placeholder: () => t("zip_code"),
      isInvalid: ({ isValid }) => !isValid,
      "data-testid": () => `${type}-zipCode`,
    },
  }),
});

const getTimeResultFormFields = ({ type, t }) => ({
  date: {
    initial: [],
    validator: every(isDateFmtValid("yyyy-MM-dd")),
    props: {
      label: always(""),
      placeholder: always(
        t(`select_${{ from: "pickup", to: "delivery" }?.[type]}_date`),
      ),
      range: always(true),
      defaultValue: ({ value }) => value,
      onChange:
        ({ set }) =>
          (value) => {
            return pipe(
              ifElse(isEmpty, constant(""), identity),
              (value) => String(value).split(" - "),
              (array) => array.map((value) => value.trim()),
              set,
            )(value);
          },
      "data-testid": () => `${type}-date`,
    },
  },
  time: {
    initial: [],
    validator: every(isDateFmtValid("kk:mm")),
    opt: true,
    props: {
      label: always(""),
      range: always(true),
      value: getPropOr([], "value"),
      onChange: ({ set }) => set,
      placeholder: always(
        t(`${{ from: "pickup", to: "delivery" }?.[type]}_time`),
      ),
      "data-testid": () => `${type}-time`,
    },
  },
});

const areAddressesSame = (...addresses) =>
  pipe(
    map(
      pick([
        "address",
        "building_no",
        "city",
        "country",
        "door_no",
        "full_address",
        "zip_code",
      ]),
    ),
    (addresses) =>
      reduce(
        (carry, item) => carry.chain(safe(equals(item))),
        getProp(0, addresses),
        addresses,
      ),
    chain(safe(not(isEmpty))),
    map(constant(true)),
    option(false),
  )(addresses);

const getFormDt = pipe(
  getProp("form"),
  chain(
    pipe(
      (form) => [form?.date?.[0], form?.time?.[0]],
      filter(isTruthy),
      safe(not(isEmpty)),
    ),
  ),
  chain(pipe((array) => [...array].join(" "), parseISO, safe(isValid))),
  option(null),
);

const getPickupDeliveryDatetime = (date, time, monoid) =>
  Maybe.of((date) => (time) => parseISO(`${date} ${time}`))
    .ap(pipe(putIntoArray, mreduce(monoid), chain(safe(not(isEmpty))))(date))
    .ap(pipe(putIntoArray, mreduce(monoid), chain(safe(not(isEmpty))))(time))
    .chain(safe(isValid))
    .option(null);
