import { t } from 'i18next';
import { lazy } from 'react';
import { getExactHiddenRoute, getRoute } from 'util/react';

export const DriverReviewsRoute = getExactHiddenRoute(
  '/driver/reviews/:carrierId',
  lazy(() => import('./reviews')),
  null
);

export const DriverCreateReviewRoute = getExactHiddenRoute(
  '/driver/create-review/:id/:to',
  lazy(() => import('./createReview')),
  null
);

export const DriverNewOrdersRoute = getExactHiddenRoute(
  '/driver/new-orders',
  lazy(() => import('./newOrders')),
  null
);

export const DriverMyOrdersRoute = getExactHiddenRoute(
  '/driver/my-orders',
  lazy(() => import('./myOrders')),
  null
);

export const DriverArchiveRoute = getExactHiddenRoute(
  '/driver/archive',
  lazy(() => import('./archive')),
  null
);

export const DriverOrderDetailsRoute = getExactHiddenRoute(
  '/driver/orders/:id',
  lazy(() => import('./orderDetails')),
  null
);

// @TODO: working-days - DARBO DIENOS IR LAIKAS
export const DriverProfileRoute = getExactHiddenRoute(
  '/driver/profile/working-days',
  lazy(() => import('./profile/index')),
  null
);

// @TODO: categories - KATEGORIJOS KURIOS VEŽAMOS
export const DriverProfileCategoriesRoute = getExactHiddenRoute(
  '/driver/profile/categories',
  lazy(() => import('./profile/categories')),
  null
);

// @TODO: loations - DARBO VIETOVĖS
export const DriverProfileLocationsRoute = getExactHiddenRoute(
  '/driver/profile/locations',
  lazy(() => import('./profile/locations')),
  null
);

// @TODO: detalis - KITA INFORMACIJA
export const DriverProfileDetailsRoute = getExactHiddenRoute(
  '/driver/profile/details',
  lazy(() => import('./profile/details')),
  null
);

export const DriverProfileSettings = getExactHiddenRoute(
  '/driver/profile/*',
  lazy(() => import('./profile/settings')),
  getRoute(
    {title: t('days_hours')},
    true,
    true,
    'a',
    lazy(() => import('./profile/working-hours')),
    null
  )
)

const DriverRoute = (
  <>
    {DriverProfileSettings}
    {DriverReviewsRoute}
    {DriverCreateReviewRoute}
    {DriverNewOrdersRoute}
    {DriverMyOrdersRoute}
    {DriverArchiveRoute}
    {DriverOrderDetailsRoute}
    {DriverProfileRoute}
    {DriverProfileCategoriesRoute}
    {DriverProfileLocationsRoute}
    {DriverProfileDetailsRoute}
  </>
)

export default DriverRoute;
